.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  padding: 20px;
  color: white;
}
.App-header img {
  height: 200px;
  background: white;
  fill: #222;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.message-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message-banner > iframe {
  width: 50vw;
  height: 50vh;
  border: 0;
}

.message {
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  color: #222;
}

.bold {
  font-weight: bold;
}

.yellowWarn {
  background: #fff3cd;
  color: #664d03;
}

.yellowText {
  color: #664d03;
}
